
import { request } from './';

const fetchLeaderboards = async group => {
    const fetchResult = await request('/groups/leaderboards/local', { group });
    return fetchResult.data;
};

const usersFetch = async () => {
    const fetchResult = await request('/users/fetch', {});
    return fetchResult.data.filter(user => !user.firstName.includes('Beta'));
};

const accountFetch = async () => {
    const fetchResult = await request('/accounts/profile', {});
    return fetchResult.data;
};

const fetchGlobals = async () => {
    const fetchResult = await request('/groups/leaderboards/global', {});
    return fetchResult.data;
};

const groupParticipants = async (data) => {
    const fetchResult = await request('/groups/members/participants', data);
    return fetchResult.data;
};

const groupRename = async (data) => {
    const fetchResult = await request('/groups/rename', data);
    return fetchResult.data;
};

const deleteGroup = async (data) => {
    const fetchResults = await request('/groups/delete', data)
    return fetchResults.data;
}

const groupRequest = async (data) =>{
    const fetchResults = await request('/groups/members/requests', data)
    return fetchResults.data;
}

const kickUser = async (data) =>{
    const fetchResults = await request('/groups/kick', data)
    return fetchResults.data;
}
const searchGroups = async (data) => {
    const fetchResults = await request('/groups/search', data)
    return fetchResults.data;
};
const viewUserGroups = async (data) => {
    const fetchResults = await request('/users/groups', data)
    return fetchResults.data;
};

const suggestions = async query => {
    try {
        const results = await request('/users/suggestions', { query });
        return results.data || [];
    } catch (e) {
        return [];
    }
};

const changeOwner = async (group, newOwner) => {
    try {
        await request('/groups/transfer', { group, user: newOwner });
        return true;
    } catch (e) {
        throw e;
    }
};

export default { fetchLeaderboards,accountFetch, viewUserGroups, fetchGlobals, deleteGroup,groupRequest, kickUser, usersFetch, groupParticipants, groupRename, searchGroups, suggestions, changeOwner };

import React from 'react';

import './PopUpShell.scss';

const PopUpShell = (props) => {
    return (
        <div className="popup">
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}

PopUpShell.propTypes = {
}

export default PopUpShell;
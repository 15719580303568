import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';
import api from '../../../api'
import styles from './styles.module.scss';
import timing from '../../../utils/timing';

const GroupDetailsPopUp = (props) => {
    const [groupData, setGroupsData] = useState(null)
    const [requestedUser, setRequestedUser] = useState(null)

    const [changeUser, setChangeUser] = useState(false);
    const [changeSuggestions, setChangeSuggestions] = useState([]);

    //gets all groups
    const getGroupData = async () => {
        try {
            const groupUsers = await api.groups.groupParticipants({ group: `${props.group._id}` })
            setGroupsData(groupUsers)
        } catch (e) {
            console.log(e)
        }
    }
    //deletes group
    const deleteGroup = async () => {
        try {
            const deleteGroup = await api.groups.deleteGroup({ group: `${props.group._id}` })
            console.log(deleteGroup)
        } catch (e) {
            console.log(e)
        }
    }
    //changes group name
    const changeName = async (newName) => {
        try {
            const deleteGroup = await api.groups.groupRename({ group: `${props.group._id}`, name: `${newName}` })
            console.log(deleteGroup)
        } catch (e) { console.log(e) }
    }
    // Request functionality
    const getRequest = async () => {
        try {
            const requestedUser = await api.groups.groupRequest({ group: `${props.group._id}` })
            setRequestedUser(requestedUser)
            console.log(requestedUser)
        } catch (e) { console.log(e) }
    }
    // kick user out of group
    const kickUser = async (user) => {
        try {
            const kickUser = await api.groups.kickUser({ group: `${props.group._id}`, user: `${user}` })
            console.log(kickUser)
            getRequest();
        } catch (e) { console.log(e) }
    }
    console.log(groupData)

    const suggestUsers = timing.debounce(async value => {
        const suggestions = await api.groups.suggestions(value);
        setChangeSuggestions(suggestions);
    });

    const changeOwner = async user => {
        try {
            await api.groups.changeOwner(props.group._id, user);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getGroupData()
        getRequest()
    }, []);

    return (
        <div className="loading-pop-up">
            {groupData && requestedUser ?
                <PopUpShell>
                    <div className={styles.GroupDetailsPopUp}>
                        <i className={`${"material-icons close-icon"} ${styles.closeIcon}`} onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                        {/* GROUP IDENTITY */}
                        <div className={styles.GroupHeader}>
                            <h1 className={styles.h1}>{props.group.name}</h1>
                            <p className={styles.groupID}>{props.group._id}</p>
                        </div>
                        {/* GROUP INFORMATION */}
                        <div className={styles.QuarterInfo}>
                            <h4 className={styles.h4}>This Quarter</h4>
                            {/* <p>{props.group.quarterPoints} points</p> */}
                        </div>
                        <div className={styles.GroupCreator}>
                            <h4 className={styles.h4}>Group Owner</h4>
                            {groupData && groupData.map(groupData => {
                                return (
                                    groupData.author ?
                                        <li className={styles.Li} key={groupData._id}>{`${groupData.firstName} ` + `${groupData.lastName} `}<button className={styles.removeUserButton} onClick={e => {
                                            setChangeUser(!changeUser);
                                        }}>Change</button></li> : null
                                );
                            })}
                            {changeUser ? <input className={styles.input} placeholder={'Search for new owner'} onChange={suggestUsers} /> : null}
                            {changeSuggestions.map(user => (
                                <li className={styles.Li} key={user._id}>{`${user.firstName} ` + `${user.lastName} `}<button className={styles.removeUserButton} onClick={async e => {
                                    if (window.confirm("Are you sure you want to change group ownership to this user?  The user will also be added to the group if not already.")) {
                                        await changeOwner(user._id);
                                        window.location.reload();
                                    } else {
                                        // Do nothing, the user cancelled
                                    }
                                }}>Make Owner</button></li>
                            ))}
                        </div>
                        <div className={styles.AmplifierList}>
                            <h4 className={styles.h4}>Amplifiers</h4>
                            {groupData && groupData.map(groupData => {

                                return (
                                    groupData.admin ?
                                        <li className={styles.Li} key={groupData._id}>{`${groupData.firstName} ` + `${groupData.lastName} `}<button className={styles.removeUserButton} onClick={e => {
                                            if (window.confirm("Are you sure you want to kick this user?  This action CANNOT be undone.")) {
                                                kickUser(groupData._id);
                                            } else {
                                                // Do nothing, the user cancelled
                                            }
                                        }}>Remove User</button></li> : null
                                );
                            })}
                        </div>
                        {/* GROUP USERS */}
                        <div className={styles.UserList}>
                            <h4 className={styles.h4}>Accepted Users</h4>

                            {groupData && groupData.map(groupData => {

                                return (
                                    groupData.admin ?
                                        null : <li className={styles.Li} key={groupData._id}>{`${groupData.firstName} ` + `${groupData.lastName} `}<button className={styles.removeUserButton} onClick={e => {
                                            if (window.confirm("Are you sure you want to kick this user?  This action CANNOT be undone.")) {
                                                kickUser(groupData._id);
                                            } else {
                                                // Do nothing, the user cancelled
                                            }
                                        }}>Remove User</button></li>
                                );
                            })}

                        </div>



                        {requestedUser ?
                            <div className={styles.UserRequests}>
                                <h4 className={styles.h4}>User Requests ({Object.keys(requestedUser || {}).length})</h4>
                                {requestedUser.map(requestedUser => {
                                    return (
                                        <li className={styles.Li}>{`${requestedUser.firstName} ` + `${requestedUser.lastName} `}<button className={styles.removeUserButton} onClick={e => {
                                            if (window.confirm("Are you sure you want to kick this user?  This action CANNOT be undone.")) {
                                                kickUser(requestedUser._id);
                                            } else {
                                                // Do nothing, the user cancelled
                                            }
                                        }}>Remove Request</button></li>
                                    );
                                })}
                            </div>
                            : null}

                        <div className={styles.buttons}>
                            <button className={`${styles.button} ${styles.addButton}`} onClick={e => {
                                let result = window.prompt("Change group name?")
                                if (result) {
                                    changeName(result)
                                    props.closeAction();
                                } else {
                                    // Do nothing, the user cancelled
                                }
                            }}>Edit Group Name</button>
                            <button className={`${styles.button} ${styles.deleteButton}`} onClick={e => {
                                if (window.confirm("Are you sure you want to delete?  This action CANNOT be undone.")) {
                                    deleteGroup();
                                    props.closeAction();
                                } else {
                                    // Do nothing, the user cancelled
                                }
                            }}>Delete Group</button>
                        </div>

                    </div>
                </PopUpShell>
                : null}
        </div>
    )
}

GroupDetailsPopUp.propTypes = {
    group: PropTypes.object.isRequired,
    closeAction: PropTypes.func
};

export default GroupDetailsPopUp;
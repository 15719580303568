import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';
import { app } from '../../../base';
import styles from './styles.module.scss';
import uuidv4 from '../../../utils/uuidv4';
import api from '../../../api';
import moment from 'moment';
const EditChoicesRewards = (props) => {
    const [photo, setPhoto] = useState(null);
    const [photoThumbURL, setPhotoThumbURL] = useState(`${props.choice.secondaryInfo.thumbnail}`);
    const [isToggled, setIsToggled] = useState(false);
    console.log("CHECK ME OUT", props)
    const [data, setData] = useState({
        group: `${props.group._id}`,
        name: `${props.choice.name}`,
        secondaryInfo: {
            company: `${props.choice.secondaryInfo.company}`,
            thumbnail: `${props.choice.secondaryInfo.thumbnail}`,
        },
        notifications: [`${props.choice.notifications}`]
    });

    const [triggerData, setTriggerData] = useState({
        group: `${props.group._id}`,
        choices: [],
        type: 'points',
        value: `${props.trigger.value}`,
        start: `${props.trigger.start}`,
        end: `${props.trigger.end}`
    })


    const updateData = (field, e) => {
        const value = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
        setData(data => ({ ...data, [field]: value }));
    };
    const updateNotificationArray = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: [value] }));
    }

    const updateTriggerData = (field, e) => {
        const value = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
        setTriggerData(triggerData => ({ ...triggerData, [field]: value }));
    };

    const updateToAndFrom = (field, e) => {
        const valuefirst = moment(e.target.value).format('YYYY-MM-DD');
        const value = valuefirst + "T00:00:00.000-0400";
        setTriggerData(triggerData => ({ ...triggerData, [field]: value }));
    };

    const updateSecondaryData = (field, e) => {
        const value = e.target.value;
        let newData = { ...data }
        let secondary = { ...newData.secondaryInfo }
        secondary[field] = value
        newData['secondaryInfo'] = secondary
        setData(newData)
    }


    const onFileChange = (e) => {
        if (!e.target || !e.target.files || e.target.files.length < 1) {
            return;
        }
        const file = e.target.files[0]
        setPhoto(file)
        const tImage = resizeImageToThumbnail(file)
        console.log(tImage)
        // Cleanup File Input
        if (e.target && e.target.value) {
            e.target.value = "";
        }
    };

    const uploadPhoto = async file => {
        console.log('this should break it but will help us', file)
        if (!file) {
            console.log('schiese lets go')
        } else {
            const p = new Promise((resolve, reject) => {
                const storageRef = app.storage().ref()
                const fileID = uuidv4();
                // Upload Original File
                const fileref = storageRef.child(`images/originals/${fileID}-${file.name}`)
                const uploadTask = fileref.put(file);
                uploadTask.on('state_changed', snap => {
                    var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                    // setPhotoProgress(progress)
                    // console.log('Upload is ' + progress + '% done');
                }, function (error) {
                    props.error(error);
                    reject(error)
                }, function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    });
                })
            })
            let url = '';
            await p.then(u => {
                url = u;
            })
            return url
        }
    };

    const uploadPhotoThumbnail = async imgSrc => {
        if (!imgSrc) {
            console.log('schiese lets go')
        } else {
            const p = new Promise((resolve, reject) => {
                const storageRef = app.storage().ref()
                const fileID = uuidv4();
                // Upload Thumbnail
                const thumbref = storageRef.child(`images/thumbnails/${fileID}-${photo.name}`)
                const thumbTask = thumbref.putString(imgSrc, 'data_url')
                thumbTask.on('state_changed', snap => {
                    var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                    // setThumbProgress(progress)
                    // console.log('Upload is ' + progress + '% done');
                }, function (error) {
                    props.error(error);
                    reject(error)
                }, function () {
                    thumbTask.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url);
                    });
                })
            })
            let url = '';
            await p.then(u => {
                url = u;
            })
            return url
        }
    };

    const resizeImageToThumbnail = img => {
        const oImage = new Image();
        oImage.onload = _ => {
            const resized = shrinkImage(oImage);
            console.log(resized)
            setPhotoThumbURL(resized.src);
        }
        oImage.src = URL.createObjectURL(img)
    };

    const shrinkImage = (img) => {
        // Create Canvas
        const canvas = document.createElement('canvas');
        canvas.style.display = "none";
        let ctx = canvas.getContext('2d');
        const size = 200;
        canvas.width = size;
        canvas.height = size;
        document.body.appendChild(canvas);

        // Calculate Size
        const offsetHorizontal = img.width >= img.height;
        const offsetPercentage = Math.abs((img.width - img.height) / 2) / (offsetHorizontal ? img.width : img.height);
        const offset = 0 - (offsetPercentage * size);
        const imageRatio = offsetHorizontal ? (img.width / img.height) : (img.height / img.width);
        const x = offsetHorizontal ? offset : 0;
        const y = !offsetHorizontal ? offset : 0;
        const width = offsetHorizontal ? size * imageRatio : size;
        const height = !offsetHorizontal ? size * imageRatio : size;
        ctx.drawImage(img, x, y, width, height);
        // Convert back to Image
        const tImage = new Image();
        tImage.src = canvas.toDataURL("image/png");

        // Cleanup DOM Elements and Revoke URLs
        document.body.removeChild(canvas)
        URL.revokeObjectURL(img.src)

        // Return resized Image
        return tImage;
    };

    const postChoice = async _ => {
        let postURL
        let thumbURL
        try {
            postURL = await uploadPhoto(photo);
            thumbURL = await uploadPhotoThumbnail(photoThumbURL);
            let formatBadgeData = { ...data, secondaryInfo: { ...data.secondaryInfo, thumbnail: thumbURL } }
            setData(formatBadgeData)

           const result = await api.rewards.addChoice(formatBadgeData)
           console.log("ID WE NEED", result)

           let formatTriggereData = { ...triggerData, choices: [result._id] }
            setTriggerData(formatTriggereData)
           
            console.log("CHECK THIS TRIGGER DATA:",formatTriggereData)

            await api.rewards.addTrigger(formatTriggereData)

            props.closeAction();
        } catch (e) {
            console.log(e)
        }
    };


    const submitNewChoice = async () => {
        await postChoice();
    }
 
    let startDate = triggerData.start
    startDate = startDate.substring(0, startDate.length - 14)

    let endDate = triggerData.end
    endDate = endDate.substring(0, endDate.length - 14)

    return (
        <div className="loading-pop-up">
            <PopUpShell>
                <div className={styles.AddBadgePopUp}>
                    <i className={`${'material-icons'} ${styles.closeIcon}`} onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                    <h1 className={styles.h1}>{"Viewing Choice"}</h1>
                    <div className={styles.Title}>
                    
                    </div>
                    <div className={styles.Title}>
                        <h4 className={styles.h4}>Name</h4>
                        <input
                            className={styles.input}
                            type="text"
                            //title ? title :
                            value={data.name}
                            onChange={e => updateData('name', e)} />
                    </div>
                    <div className={styles.DescriptionText}>
                        <h4 className={styles.h4}>Company</h4>
                        <textarea className={styles.inputs} value={data.secondaryInfo.company} onChange={e => updateSecondaryData('company', e)} />
                    </div>
                    <div className={styles.DescriptionText}>
                        <h4 className={styles.h4}>Notifications for company</h4>
                        <textarea className={styles.inputs} value={data.notifications} onChange={e => updateNotificationArray('notifications', e)} />
                    </div>
                    <div className={styles.ImagePicker}>
                        <h4 className={styles.h4}>Image</h4>
                        {photoThumbURL ? <img src={photoThumbURL} /> : null}
                        <input className={styles.filePicker} type="file" accept="image/x-png,image/gif,image/jpeg" style={{ marginTop: "12px" }} onChange={onFileChange} />
                    </div>
                    <div className={styles.LabelContainer}>
                            <h4 className={styles.h4} style={{ marginBottom: '12px' }}>Points Value</h4>
                            <input className={styles.input} value={triggerData.value} type="number" onChange={e => updateTriggerData('value', e)} />
                        </div>
                    <div className={styles.DatePicker}>
                        <h4 className={styles.h4}>Availability</h4>
                        <div className={styles.Selector}>
                            <div className={styles.DateContainer}>
                                <h5 className={styles.h5}>From</h5>
                                <div className={styles.dateInput}>{startDate}</div>
                            </div>
                            <div className={styles.DateContainer}>
                                <h5 className={styles.h5}>To</h5>
                                <div className={styles.dateInput}>{endDate}</div>
                            </div>
                        </div>
                    </div> 
                    {/* <div className={styles.SaveButton} onClick={() => submitNewChoice()}>Update Choice</div> */}
                </div>

            </PopUpShell>
        </div >
    );
};

EditChoicesRewards.propTypes = {
    closeAction: PropTypes.func,
};

export default EditChoicesRewards;
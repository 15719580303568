
import React, { useState } from 'react';
import style from './style.module.css';
import { Link } from 'react-router-dom';
import conditional from '../../utils/conditional';
import phone from '../../utils/phone';
import api from '../../api/index';

const SignInVerify = props => {
    
    const [data, setData] = useState({ code: '' });
    const [status, setStatus] = useState('waiting');

    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };

    const containerClass = conditional('container', style, { 
        pending: status === 'pending',
        finished: status === 'finished'
    });

    const submit = async () => {
        console.log('submit')
        try {
            const phoneNumber = phone.format(localStorage.getItem('phone') || '');
            const result = await api.auth.verifyCode(phoneNumber, data.code);
            console.log(result);
            props.onAuth(result);
            
            setStatus('finished');
            setTimeout(() => props.history.push('/'), 1000);
        } catch (e) {
            console.log(e)
            const error = new Error('Incorrect credentials');
            error.code = 'incorrect-credentials';
            props.error(error);
        }
    };

    return (
        <div className={style.SignIn}>
            <div className={containerClass}>
                <div className={style.title + ' h1'}>Enter Verification Code</div>
                <div className={style.description + ' h2'}>Check your text messages and enter the code that was sent to your phone.</div>
                <input className={style.textField + ' i1'} placeholder="Code" inputMode="numeric" value={data.code} onChange={e => updateData('code', e)} />
                <div className={style.submit + ' b1'} onClick={submit}><span className={'h1'}>Verify</span></div>
            </div>
        </div>
    );

};

export default SignInVerify;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import GroupItemContainer from '../GroupItemContainer';
import GroupItem from '../GroupItem';
import ContentHeader from '../ContentHeader';
import Search from '../SearchComponent';
import api from '../../api'

import GroupDetailsPopUp from '../../components/PopUps/GroupDetailsPopUp';

const Groups = props => {
    const [userData, setUserData] = useState(null)
    const getUserData = async () => {
        try {
            const result = await api.groups.fetchGlobals()
            setUserData(result)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getUserData()
    }, []);

    console.log(userData)
    const [downloadLink, setDownloadLink] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    // const [loading, setLoading] = useState(false);

    function DownloadGroupsFile() {
        //Originally tailored for using FireHelper to pass CSV as download
        // FH.DownloadGroupsFile(groups, (csvLink) => {
        //     setDownloadLink(csvLink);
        // });

        //Will need to be reformatted for Mongo, then using setDownloadLink hook 
        //to trigger conditional in within the return
    }

    return (
        <div className={styles.Groups}>
            <ContentHeader>Groups</ContentHeader>
            <Search />
            <GroupItemContainer
                column1="Group Name"
                column2="Quarter Points"
                column3="Total Users"
            >
                {userData && userData.map(userData => {
                    return (
                        <GroupItem
                            key={userData._id}
                            groupColumn1={userData.name}
                            groupColumn2={userData.quarterPoints}
                            groupColumn3={userData.userCount}
                            onClick={() => setSelectedGroup(userData)}
                        />
                    );
                })}
            </GroupItemContainer>
            {selectedGroup ?
            <GroupDetailsPopUp
                group={selectedGroup}
                closeAction={() => {
                    setSelectedGroup(null);
                    getUserData();
                }}
                />
                :null}
        </div>
    );
};

Groups.propTypes = {};

export default Groups;

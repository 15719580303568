import React from 'react';
import ContentHeader from '../ContentHeader';
import Card from '../Card';
import GeneralCard from '../Cards/GeneralCard';
import DailyMotivationCard from '../Cards/DailyMotivationCard';

import styles from './styles.module.scss';

const Home = props => {
    return (
        <div className={styles.Home}>
            <ContentHeader>Home</ContentHeader>
            
        </div>
    );
};

export default Home;

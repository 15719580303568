import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import { BrowserRouter, Route, withRouter, Redirect, Switch } from 'react-router-dom';
import api from '../../api'
// import TabBar from '../TabBar';
import Logo from '../../assets/logo.png'
import SidebarButton from '../SidebarButton'
import SideBar from '../Sidebar'
import Home from '../Home';
import SignIn from '../SignIn';
import SignOut from '../SignOut';
import SignInVerify from '../SignInVerify';
import conditional from '../../utils/conditional';

//MainContent Pages
import Badges from '../Badges';
import Users from '../Users';
import Groups from '../Groups';
import Analytics from '../Analytics'
import Rewards from '../Rewards'

const routes = [
    { path: '/', component: Home },
    { path: '/analytics', component: Analytics },
    { path: '/badges', component: Badges },
    { path: '/users', component: Users },
    { path: '/groups', component: Groups },
    { path: '/rewards', component: Rewards }
    // { path: '/invite/:code', component: InvitePage }
];

const walls = {
    '/sign-in': SignIn,
    '/sign-out': SignOut,
    '/verify': SignInVerify,

};

const Routes = props => {

    const [userAdmin, setUserAdmin] = useState(null)
    const checkAdmin = async () => {
        try {
            const checkAdmin = await api.groups.accountFetch()
            setUserAdmin(checkAdmin)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        checkAdmin()
    }, []);
    console.log()

    const renderedRoutes = routes.map(route => {
        const Component = route.component;

        const routeContent = conditional('routeContent', style, { visible: props.history.location.pathname === route.path });

        return (
            <div className={style.fakeAssRouteHolder}>
                <Route exact key={route.path} path={route.path} render={() => <div key={route.path} />} />
                <div className={routeContent}>
                    <Component
                        key={route.path}
                        path={route.path}
                        history={props.history}
                        auth={props.auth} />
                </div>
            </div>
        );
    });
    //  localStorage.setItem('authData', null); props.history.push('/sign-in')
    return (
        <div className={style.Routes}>
            {userAdmin && userAdmin.admin ?
                <div className={style.routeContainer}>
                    <div className={style.App}>
                        <BrowserRouter history={History}>
                            <>
                                <section className={style.mainContent}>
                                    <Switch>
                                        <Route exact={true} path='/' component={Analytics} />
                                        {/* <Route exact={true} path='/analytics' component={Analytics} /> */}
                                        <Route exact={true} path='/badges' component={Badges} />
                                        <Route exact={true} path='/users' component={Users} />
                                        <Route exact={true} path='/groups' component={Groups} />
                                        <Route exact={true} path='/rewards' component={Rewards}/>
                                    </Switch>
                                </section>
                                <SideBar history={props.history} headerImage={Logo} classPass="">
                                    {/* <SidebarButton to="/" userHint="Home" materialIcon="home" /> */}
                                    <SidebarButton to="/" userHint="Analytics" materialIcon="bar_chart" />
                                    <SidebarButton to="/badges" userHint="Badges" materialIcon="grade" />
                                    <SidebarButton to="/users" userHint="Users" materialIcon="person" />
                                    <SidebarButton to="/groups" userHint="Groups" materialIcon="group" />
                                    <SidebarButton to="/rewards" userHint="Rewards" materialIcon="grade" />
                                </SideBar>
                                <div id="download-users-file-host-container" style={{ "display": "none" }} >This is here for the downloaded users csv file</div>
                                <div id="download-groups-file-host-container" style={{ "display": "none" }} >This is here for the downloaded groups csv file</div>
                            </>
                        </BrowserRouter>

                    </div>
                </div>
                :
                userAdmin ?
                    <div className={style.routeContainer}>
                        <div className={style.App}>
                            <BrowserRouter history={History}>
                                <>
                                    <section className={style.mainContent}>
                                        <Switch>
                                            <Route exact={true} path='/' component={Analytics} />
                                            {/* <Route exact={true} path='/analytics' component={Analytics} /> */}
                                        </Switch>
                                    </section>
                                    <SideBar history={props.history} headerImage={Logo} classPass="">
                                        {/* <SidebarButton to="/" userHint="Home" materialIcon="home" /> */}
                                        <SidebarButton to="/" userHint="Analytics" materialIcon="bar_chart" />
                                    </SideBar>
                                </>
                            </BrowserRouter>

                        </div>
                    </div>
                    : null}
        </div>
    );

};

const Container = withRouter(props => {
    const [auth, setAuth] = useState(null);


    const Wall = props.location.pathname.includes('/l/') ? walls['/l'] : walls[props.location.pathname];

    if (Wall) return (
        <div className={style.wallContainer + ' background'}>
            <Wall
                history={props.history}
                path={props.location.pathname}
                onAuth={result => setAuth(result)} />
        </div>
    );

    const authData = auth || props.authData;
    if (!authData) return <Redirect to="/sign-in" />;
    return (
        <div>
            <div>
                <Routes
                    history={props.history}
                    auth={authData} />
            </div>
            {/* <TabBar history={props.history} /> */}
        </div>
    );

});

const App = props => (
    <div className={style.App}>
        <BrowserRouter>
            <Container authData={props.authData} />
        </BrowserRouter>
    </div>
);

export default App;

import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ContentHeader = props => {
    return (
        <div className={styles.ContentHeader}>
            <h2 className={styles.ContentText}>
                {props.children}
            </h2>
        </div>
    );
};

ContentHeader.propTypes = {};

export default ContentHeader;
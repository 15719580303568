import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './SidebarButton.scss';

const SidebarButton = props => {
    return (
        <li>
            <Link exact="true" to={props.to} activeClassName="is-active" className="nav-link">
                <i className="material-icons">{props.materialIcon}</i>
                <div className="user-hint">{props.userHint}</div>
            </Link>
        </li>
    )
}

SidebarButton.propTypes = {
    materialIcon: PropTypes.string.isRequired,
    userHint: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
}

export default SidebarButton;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentHeader from '../ContentHeader';
import CardContainer from '../CardContainer';
import Card from '../Card';
import RewardCard from '../Cards/RewardCard';
import api from '../../api'
import styles from './styles.module.scss';
import AddChoicesRewards from '../PopUps/AddChoices&Rewards';
import ListChoicesTriggers from '../PopUps/ListChoicesTriggers';
import AddRewardPopUp from '../PopUps/AddRewardPopUp';
import EditChoicesRewards from '../PopUps/EditChoices&Rewards';
const Badges = props => {
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [selectedTrigger, setSelectedTrigger] = useState(null);
    const [groupData, setGroupData] = useState()
    const [adding, setAdding] = useState(false);
    const [listChoicesTriggers, setListChoicesTriggers] = useState(false)
    const [editing, setEditing] = useState(false);
    const [editingChoice, setEditingChoice] = useState(false);
    const getGroupData = async () => {
        try {
            const result = await api.rewards.getGroups()
            setGroupData(result)
        } catch (e) {
            console.log(e)
        }
    }
    const addChoices = () => {
        setListChoicesTriggers(false)
        setEditing(true)
    }
    useEffect(() => {
        getGroupData()
    }, []);
    console.log(selectedChoice)

    return (
        <div className={styles.Badges}>
            <div className={styles.HeaderButtonWrapper}>
                <ContentHeader>Rewards</ContentHeader>
                <button
                    className={styles.Button}
                    onClick={() => { setSelectedBadge(true); setAdding(true); }}

                >
                    <span className={`${"material-icons"} ${styles.Icon}`}>add_box</span>
                </button>
            </div>
            <CardContainer>
                {groupData && groupData.map(groupData => {
                    return (
                        <Card key={groupData._id}>
                            <RewardCard badge={groupData} onEditClicked={() => {
                                setSelectedBadge(groupData);
                                setListChoicesTriggers(true)
                            }} />
                        </Card>
                    )
                })}
            </CardContainer>
            {editing ?
                <AddChoicesRewards
                    group={selectedBadge}
                    closeAction={() => {
                        setEditing(false);
                        setAdding(false);
                        setSelectedBadge(null)
                        getGroupData()
                    }}
                />

                : null}
            {editingChoice ?
                <EditChoicesRewards
                    group={selectedBadge}
                    choice={selectedChoice}
                    trigger={selectedTrigger}
                    closeAction={() => {
                        setEditing(false);
                        setAdding(false);
                        setEditingChoice(false)
                        setSelectedBadge(null)
                        getGroupData()
                    }}
                />

                : null}
            {adding ?
                <AddRewardPopUp
                    closeAction={() => {
                        setSelectedBadge(null)
                        getGroupData()
                        setEditing(false);
                        setAdding(false);
                    }}
                />

                : null}

            {listChoicesTriggers ?
                <ListChoicesTriggers
                    group={selectedBadge}
                    addChoice={() => { addChoices() }}
                    refresh={() => { getGroupData() }}
                    selectChoice={(choice, trigger) => { setSelectedChoice(choice); setSelectedTrigger(trigger); setEditingChoice(true); setListChoicesTriggers(false)}}
                    closeAction={() => {
                        setEditing(false);
                        setAdding(false);
                        setListChoicesTriggers(false)
                        setSelectedBadge(null)
                        getGroupData()
                    }}
                />

                : null}


        </div>
    );
};

Badges.propTypes = {};

export default Badges;
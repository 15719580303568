import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentHeader from '../ContentHeader';
import CardContainer from '../CardContainer';
import Card from '../Card';
import BadgeCard from '../Cards/BadgeCard';
import api from '../../api'
import styles from './styles.module.scss';
import EditBadgePopUp from '../PopUps/EditBadgePopUp';
import AddBadgePopUp from '../PopUps/AddBadgePopUp';
const Badges = props => {
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [badgeData, setBadgeData] = useState()
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(false);
    const getBadgeData = async () => {
        try {
            const result = await api.badges.fetchBadges()
            setBadgeData(result)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getBadgeData()
    }, []);
    console.log(badgeData);

    return (
        <div className={styles.Badges}>
            <div className={styles.HeaderButtonWrapper}>
                <ContentHeader>Badges</ContentHeader>
                <button
                    className={styles.Button}
                    onClick={() => { setSelectedBadge(true); setAdding(true); }}
                    
                >
                    <span className={`${"material-icons"} ${styles.Icon}`}>add_box</span>
                </button>
            </div>
            <CardContainer>
                {badgeData && badgeData.map(badgeData => {
                    return (
                        <Card key={badgeData._id}>
                            <BadgeCard badge={badgeData} onEditClicked={() => {
                                setSelectedBadge(badgeData);
                                setEditing(true)
                            }} />
                        </Card>
                    )
                })}
            </CardContainer>
            {editing ?
                <EditBadgePopUp
                    badge={selectedBadge}
                    closeAction={() => {
                        setEditing(false);
                        setAdding(false);
                        setSelectedBadge(null)
                        getBadgeData()
                    }}
                />

                : null}
            {adding ?
                <AddBadgePopUp
                    closeAction={() => {
                        setSelectedBadge(null)
                        getBadgeData()
                        setEditing(false);
                        setAdding(false);
                    }}
                />

                : null}


        </div>
    );
};

Badges.propTypes = {};

export default Badges;

import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import api from '../../api';
import AnalyticsButtons from '../AnalyticsButtons';
import ContentHeader from '../ContentHeader';

const Analytics = props => {
    const [fetchAnalytics, setFetchAnalytics] = useState([]);

    const [averageType, setAverageType] = useState('app');
    const [averageTime, setAverageTime] = useState('month');
    const [averageIdentifier, setAverageIdentifier] = useState(null);
    const [averages, setAverages] = useState([]);

    const [userData, setUserData] = useState(null)
    const [downloadingIds, setDownloadingIds] = useState([]);
    const [range, setRange] = useState({ from: null, to: null });

    const fetchData = async () => {
        try {
            const analytics = await api.analytics.analytics();
            const result = await api.groups.usersFetch()
            setFetchAnalytics(analytics)

            var results = result.reduce((unique, o) => {
                if(!unique.some(obj => obj.firstName === o.firstName && obj.lastName === o.lastName && obj.phone === o.phone)) {
                  unique.push(o);
                }
                return unique;
            },[]);
            // results = results.filter(function( obj ) {
            //     return obj.quarterPoints === 0;
            // });

            for (let i = results.length - 1; i >= 0; --i) {
                // if (results[i].quarterPoints === 0 && results[i].quarterSteps === 0) {
                //     results.splice(i,1);
                // }

                if (!(results[i].secondaryInfo || {}).area) results.splice(i, 1);
            }

            setUserData(results.length)

        } catch (e) {
            console.log(e)
        }
    };
    console.log(userData)

    const tier = points => {
        if (points > 15000) return 'Platinum';
        if (points > 9000) return 'Gold';
        if (points > 6000) return 'Silver';
        if (points > 3000) return 'Bronze';
        return 'None';
    };

    const fetchAverages = async () => {
        setAverageIdentifier(null);

        const averages = await api.analytics.averages(averageType, averageTime);
        setAverages(averages);

        console.log(averages);

        if (averages.length) setAverageIdentifier(averages[0].identifier);
    };

    const downloadEmails = async () => {
        const emails = await api.analytics.emails();
        convertAndDownloadCSV(emails);
    };

    const downloadAverages = async () => {
        if (averageType === 'app') {
            const data = Object.assign(averages, [])
                .map(d => ({ ...d, change: (d.change * 100).toFixed(2) + '%' }));
            data.shift();
            convertAndDownloadCSV(data);
            return;
        }

        try {
            console.log(averages);
            console.log('download averages', averageIdentifier);
            const selected = averages.filter(average => average.identifier === averageIdentifier);
            if (selected.length < 1) return;

            const download = selected[0].groups
                .map(d => ({ ...d, change: (d.change * 100).toFixed(2) + '%' }));
            download.shift();
            console.log(download);
            convertAndDownloadCSV(download);
        } catch (e) {
            console.log(e);
            alert('Error fetching average data');
        }
    };

    const download = id => async () => {
        setDownloadingIds({ ...downloadingIds, [id]: true });

        if (id === 'users') {
            try {
                const users = await api.analytics.users(range);

                const flatEarth = users.map(item => ({
                    _id: item._id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    groupName: item.groups.length ? item.groups[0].name : 'None',
                    phone: item.phone,
                    steps: item.aggregate.steps + '',
                    points: item.aggregate.points + ''
                }));

                convertAndDownloadCSV(flatEarth, id);
            } catch (e) {
                alert('Error fetching analytics data');
            }
        }

        if (id === 'groups') {
            try {
                const groups = await api.analytics.groups(range);

                const flatEarth = groups.map(item => ({
                    _id: item._id,
                    name: item.name,
                    aggregateSteps: item.aggregate.steps + '',
                    aggregatePoints: item.aggregate.points + '',
                    averageUserSteps: item.average.steps.toFixed(2),
                    averageUserPoints: item.average.points.toFixed(2)
                }));

                convertAndDownloadCSV(flatEarth, id);
            } catch (e) {
                alert('Error fetching analytics data');
            }
        }

        if (id === 'tiers') {
            try {
                const users = await api.analytics.users(range);

                const flatEarth = users.map(item => ({
                    _id: item._id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    phone: item.phone,
                    tier: tier(item.aggregate.points)
                }));
                
                convertAndDownloadCSV(flatEarth, id);
            } catch (e) {
                alert('Error fetching analytics data');
            }
        }

        if (id === 'redemptions') {
            try {
                const redemptions = await api.analytics.redemptions(range);
                
                const flatEarth = redemptions.map(item => ({
                    _id: item._id,
                    userFirstName: item.user.firstName,
                    userLastName: item.user.lastName,
                    userPhone: item.user.phone,
                    groupId: item.group._id,
                    groupName: item.group.name,
                    choiceId: item.choice._id,
                    choiceName: item.choice.name,
                    choiceCompany: (item.choice.secondaryInfo || {}).company || '',
                    redeemed: item.redeemed ? 'Yes' : 'No',
                    date: item.date
                }));

                convertAndDownloadCSV(flatEarth, id);
            } catch (e) {
                alert('Error fetching analytics data');
            }
        }

        if (id === 'registrations') {
            try {
                const registrations = await api.analytics.registrations(range);
                convertAndDownloadCSV(registrations, id);
            } catch (e) {
                alert('Error fetching analytics data');
                console.log(e);
            }
        }

        setDownloadingIds({ ...downloadingIds, [id]: false });
    };

    // dirty af hack but prob not gonna be issue
    const cleanValue = value => typeof value === 'string' ? value.split(',').join('').split('\n').join('') : (typeof value === 'number' ? value : '');

    const convertAndDownloadCSV = (data, tag = 'analytics') => {
        if (!data.length || data.length < 1) return null;

        const fields = Object.keys(data[0]);
        let output = fields.join(',') + '\n';

        for (const item of data) {
            output += fields.map(field => cleanValue(item[field])).join(',');
            output += '\n';
        }

        const now = new Date();
        const blob = new Blob([output], { type: 'text/csv' });
        
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = window.URL.createObjectURL(blob);
        a.download = tag + '_' + now.getMonth() + '_' + now.getDate() + '_' + now.getFullYear() + '.csv';
        a.click();
    };

    const downloadArchive = async (type, time) => {
        const archives = await api.analytics.archive(type, time);
        if (!archives.length || archives.length < 1) return null;

        const now = new Date();
        const blob = new Blob([archives], { type: 'text/csv' });
        
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = window.URL.createObjectURL(blob);
        a.download = 'archive' + '_' + now.getMonth() + '_' + now.getDate() + '_' + now.getFullYear() + '.csv';
        a.click();
    };

    const changeRange = param => e => setRange({ ...range, [param]: e.target.value + 'T00:00:00.000-0400' });

    const downloadClass = id => conditional('download', style, { busy: downloadingIds[id], disabled: !rangeValid });
    const downloadTitle = id => downloadingIds[id] ? 'Downloading Analytics...' : (rangeValid ? 'Download Analytics' : 'Please Select Range');
    const downloadButton = id => <button className={downloadClass(id)} onClick={download(id)}>{downloadTitle(id)}</button>;

    const rangeValid = range.from && range.to && !isNaN(Date.parse(range.from)) && !isNaN(Date.parse(range.to)) && Date.parse(range.from) < Date.parse(range.to);

    useEffect(() => { fetchData() }, [])

    useEffect(() => {
        fetchAverages();
    }, [averageType, averageTime]);

    return (
        <div className={style.Analytics}>
            <ContentHeader>Analytics</ContentHeader>

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Average Steps By Group (Archived)</h1>
            <button className={style.download} onClick={() => downloadArchive('averageSteps', 'month')}>{'Average Steps Monthly'}</button>
            <button className={style.download} onClick={() => downloadArchive('averageSteps', 'quarter')}>{'Average Steps Quarterly'}</button>
            <button className={style.download} onClick={() => downloadArchive('averageSteps', 'year')}>{'Average Steps Yearly'}</button>

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Trends By Group (Archived)</h1>
            <button className={style.download} onClick={() => downloadArchive('changeAverageSteps', 'month')}>{'Trends Monthly'}</button>
            <button className={style.download} onClick={() => downloadArchive('changeAverageSteps', 'quarter')}>{'Trends Quarterly'}</button>
            <button className={style.download} onClick={() => downloadArchive('changeAverageSteps', 'year')}>{'Trends Yearly'}</button>

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Averages</h1>
            <div className={style.section}>
                <div className={style.select}>
                    <select style={{ display: 'block' }} onChange={e => setAverageType(e.target.value.toLowerCase())}>
                        <option>App</option>
                        <option>Group</option>
                    </select>
                </div>
                
                <div className={style.select} style={{ display: 'block' }} onChange={e => setAverageTime(e.target.value.toLowerCase())}>
                    <select style={{ display: 'block' }}>
                        <option value="month">Monthly</option>
                        <option value="quarter">Quarterly</option>
                        <option value="year">Yearly</option>
                    </select>
                </div>

                <div className={style.select}>
                    <select style={{ display: averageType === 'group' ? 'block' : 'none' }} onChange={e => setAverageIdentifier(e.target.value)}>
                        {averages.map(average => <option>{average.identifier}</option>)}
                    </select>
                </div>

                <br />
                <button className={style.download} onClick={() => downloadAverages()}>Download</button>
                <br />
            </div>

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Emails</h1>
            <div className={style.section}>
                <button className={style.download} onClick={() => downloadEmails()}>Download Emails</button>
                <br />
            </div>

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Custom Date Range</h1>
            <div className={style.section}>
                <div className={style.date}>
                    <input type="date" onChange={changeRange('from')} />
                </div>
                <p>to</p>
                <div className={style.date}>
                    <input type="date" onChange={changeRange('to')} />
                </div>
            </div>
            
            <h2>Users</h2>
            <a href="https://api.activemarion.app/analytics/download/users/month/password/PPSHFW6U">Monthly</a>
            <a href="https://api.activemarion.app/analytics/download/users/quarter/password/PPSHFW6U">Quarterly</a>
            <a href="https://api.activemarion.app/analytics/download/users/year/password/PPSHFW6U">Yearly</a>
            
            <h2>Groups</h2>
            <a href="https://api.activemarion.app/analytics/download/groups/month/password/PPSHFW6U">Monthly</a>
            <a href="https://api.activemarion.app/analytics/download/groups/quarter/password/PPSHFW6U">Quarterly</a>
            <a href="https://api.activemarion.app/analytics/download/groups/year/password/PPSHFW6U">Yearly</a>

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>User fitness data by custom date range</h1>
            {downloadButton('users')}

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Group fitness data by custom date range</h1>
            {downloadButton('groups')}

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Users by tier of badges or points</h1>
            {downloadButton('tiers')}

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Outstanding and redeemed coupons with choices</h1>
            {downloadButton('redemptions')}

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>User registrations by custom date range</h1>
            {downloadButton('registrations')}

            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Current number of active users on AMP: {userData}</h1>
            <h1 style={{ color: 'white', marginBottom:'10px', marginTop: '20px', fontWeight: '600' }}>Current organizations</h1>
         {fetchAnalytics && fetchAnalytics.map(fetchAnalytics => {
                    return (
                        <AnalyticsButtons
                        fetchId={fetchAnalytics._id}
                        group={fetchAnalytics.name}
                        />
                    );
                })}
        </div>
    );

};

export default Analytics;

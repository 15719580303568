import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';
import { app } from '../../../base';
import styles from './styles.module.scss';
import api from '../../../api';
const ListChoicesTriggers = (props) => {

    const archiveChoice = async (choice, trigger) => {
        try {
           await api.rewards.archiveChoice({ group: `${props.group._id}`, choice: `${choice}` })
           await api.rewards.archiveTrigger({ group: `${props.group._id}`, trigger: `${trigger}` })
            props.closeAction()
        } catch (e) { console.log(e) }
    }

    const archiveGroup = async () => {
        try {
            await api.rewards.archiveGroup({ group: `${props.group._id}`})
            props.closeAction()
        } catch (e) { console.log(e) }
    }
    console.log(props)
    return (
        <div className="loading-pop-up">
            <PopUpShell>
                <div className={styles.AddBadgePopUp}>
                    <i className={`${'material-icons'} ${styles.closeIcon}`} onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                    <h1 className={styles.h1}>{"Current Available Choices For " + props.group.name + " Group"}</h1>
                    {props.group.triggers.map(choiceData => {
                        const choice = choiceData.choices[0]._id
                        return (
                            choiceData.choices[0] && choiceData.choices[0].archived ?
                                null
                                :
                                <div key={choiceData.choices[0] && choiceData.choices[0]._id} className={styles.choicelist}>
                                    <li className={styles.Li} key={choiceData.choices[0] && choiceData.choices[0]._id}>{`${choiceData.choices[0] && choiceData.choices[0].name ? choiceData.choices[0].name: 'No Name'}`}</li>
                                    <button className={styles.editChoiceButton} onClick={e => { props.selectChoice(choiceData.choices[0], choiceData);}}>View Choice</button>
                                    <button className={styles.removeChoiceButton} onClick={e => {
                                        if (window.confirm("Are you sure you want to delete this choice?  This action CANNOT be undone.")) {
                                            console.log(choice)
                                            archiveChoice(choice, choiceData._id)
                                            // archiveChoice(choiceData.choices[0]._id);
                                            //archive choice
                                        } else {
                                            // Do nothing, the user cancelled
                                        }
                                    }}>Remove Choice</button>
                                </div>
                        );
                    })}
                </div>
                <div className={styles.SaveButton} onClick={() => props.addChoice()}>Add Choice</div>
                <div className={styles.DeleteButton} onClick={e => {
                    if (window.confirm("Are you sure you want to delete this Group?  This action CANNOT be undone.")) {
                        archiveGroup()
                    } else {
                        // Do nothing, the user cancelled
                    }
                }}>Delete Group</div>
            </PopUpShell>
        </div >
    );
};

ListChoicesTriggers.propTypes = {
    closeAction: PropTypes.func,
};

export default ListChoicesTriggers;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';
import { app } from '../../../base';
import styles from './styles.module.scss';
import uuidv4 from '../../../utils/uuidv4';
import api from '../../../api';
import groups from '../../../api/groups';

const EditBadgePopUp = (props) => {
    const [photo, setPhoto] = useState(null);
    const [photoThumbURL, setPhotoThumbURL] = useState('');
    const [data, setData] = useState({
        badge: `${props.badge._id}`,
        update: {
            name: `${props.badge.name}`,
            secondaryInfo: {
                description: `${props.badge.secondaryInfo.description}`,
                instruction: `${props.badge.secondaryInfo.instruction}`,
                completion: `${props.badge.secondaryInfo.completion}`,
                photo: `${props.badge.secondaryInfo.photo}`,
                thumbnail: `${props.badge.secondaryInfo.photo}`
            },
            groupInfo: {
                hideAfter: `${props.badge.groupInfo.hideAfter}`,
                identifier: `${props.badge.groupInfo.identifier}`,
                rank: props.badge.groupInfo.rank
            }
        }
    });

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const updateData = (field, e) => {
        const value = e.target.value;
        let newData = { ...data }
        let update = { ...newData.update }
        update[field] = value
        newData['update'] = update
        setData(newData)
    };

    const updateSecondaryData = (field, e) => {
        const value = e.target.value;
        let newData = { ...data }
        let secondary = { ...newData.update.secondaryInfo }
        secondary[field] = value
        newData.update['secondaryInfo'] = secondary
        setData(newData)
    };

    const updateGroupInfo = (field, e) => {
        const value = e.target.value;
        let newData = { ...data }
        let group = { ...newData.update.groupInfo }
        group[field] = value
        newData.update['groupInfo'] = group
        setData(newData)
    };

    const onFileChange = (e) => {
        if (!e.target || !e.target.files || e.target.files.length < 1) {
            return;
        }
        const file = e.target.files[0]
        setPhoto(file)
        const tImage = resizeImageToThumbnail(file)
        console.log(tImage)
        // Cleanup File Input
        if (e.target && e.target.value) {
            e.target.value = "";
        }
    };

    const uploadPhoto = async file => {
        console.log('this should break it but will help us', file)
        if (!file) {
            console.log('schiese lets go')
        } else {
            const p = new Promise((resolve, reject) => {
                const storageRef = app.storage().ref()
                const fileID = uuidv4();
                // Upload Original File
                const fileref = storageRef.child(`images/originals/${fileID}-${file.name}`)
                const uploadTask = fileref.put(file);
                uploadTask.on('state_changed', snap => {
                    var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                    // setPhotoProgress(progress)
                    // console.log('Upload is ' + progress + '% done');
                }, function (error) {
                    props.error(error);
                    reject(error)
                }, function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    });
                })
            })
            let url = '';
            await p.then(u => {
                url = u;
            })
            return url
        }
    };

    const uploadPhotoThumbnail = async imgSrc => {
        if (!imgSrc) {
            console.log('schiese lets go')
        } else {
            const p = new Promise((resolve, reject) => {
                const storageRef = app.storage().ref()
                const fileID = uuidv4();
                // Upload Thumbnail
                const thumbref = storageRef.child(`images/thumbnails/${fileID}-${photo.name}`)
                const thumbTask = thumbref.putString(imgSrc, 'data_url')
                thumbTask.on('state_changed', snap => {
                    var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                    // setThumbProgress(progress)
                    // console.log('Upload is ' + progress + '% done');
                }, function (error) {
                    props.error(error);
                    reject(error)
                }, function () {
                    thumbTask.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url);
                    });
                })
            })
            let url = '';
            await p.then(u => {
                url = u;
            })
            return url
        }
    };

    const resizeImageToThumbnail = img => {
        const oImage = new Image();
        oImage.onload = _ => {
            const resized = shrinkImage(oImage);
            console.log(resized)
            setPhotoThumbURL(resized.src);
        }
        oImage.src = URL.createObjectURL(img)
    };

    const shrinkImage = (img) => {
        // Create Canvas
        const canvas = document.createElement('canvas');
        canvas.style.display = "none";
        let ctx = canvas.getContext('2d');
        const size = 200;
        canvas.width = size;
        canvas.height = size;
        document.body.appendChild(canvas);

        // Calculate Size
        const offsetHorizontal = img.width >= img.height;
        const offsetPercentage = Math.abs((img.width - img.height) / 2) / (offsetHorizontal ? img.width : img.height);
        const offset = 0 - (offsetPercentage * size);
        const imageRatio = offsetHorizontal ? (img.width / img.height) : (img.height / img.width);
        const x = offsetHorizontal ? offset : 0;
        const y = !offsetHorizontal ? offset : 0;
        const width = offsetHorizontal ? size * imageRatio : size;
        const height = !offsetHorizontal ? size * imageRatio : size;
        ctx.drawImage(img, x, y, width, height);
        // Convert back to Image
        const tImage = new Image();
        tImage.src = canvas.toDataURL("image/png");

        // Cleanup DOM Elements and Revoke URLs
        document.body.removeChild(canvas)
        URL.revokeObjectURL(img.src)

        // Return resized Image
        return tImage;
    };

    const postData = async _ => {
        let postURL
        let thumbURL
        if (!photo && !photoThumbURL) {
            try {
                console.log('no image updates but sending new or old data: ', data)
                console.log('check second one ', data)
                await api.badges.editBadges(data)
                props.closeAction();
            } catch (e) {
                console.log(e)
            }
        } else {
            try {
                postURL = await uploadPhoto(photo);
                thumbURL = await uploadPhotoThumbnail(photoThumbURL);
                let formatBadgeData = { ...data, update: { ...data.update, secondaryInfo: { ...data.update.secondaryInfo, photo: postURL, thumbnail: thumbURL } } }
                setData(formatBadgeData)
                console.log('updates image also sends old or new data: ', formatBadgeData)
                console.log('check second one ', data)
                await api.badges.editBadges(formatBadgeData)
                props.closeAction();
            } catch (e) {
                console.log(e)
            }
        }
    };


    // const currentRequirement = props.badge.requirements.steps !== null ? "steps" : "points";

    const submitEdit = async () => {
        await postData();
    }

    const deleteBadge = async () => {
        if (window.confirm("Warning: This will not delete badges from users who earned them, only going forward people can't earn this.")) {
            await api.badges.archiveBadges({ badge: props.badge._id });
            window.location.reload();
        } else {
            // Do nothing, the user cancelled
        }
    };

    var date = new Date(data.update.groupInfo.hideAfter);
    convert(date)
    console.log(data)


    return (
        <div className="loading-pop-up">
            <PopUpShell>
                <div className={styles.EditBadgePopUp}>
                    <i className={`${'material-icons'} ${styles.closeIcon}`} onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                    <h1 className={styles.h1}>{!props.adding ? "Edit Badge" : "Add Badge"}</h1>
                    <div className={styles.title}>
                        <h4 className={styles.h4}>Title</h4>
                        <input
                            className={styles.input}
                            type="text"
                            //title ? title :
                            value={data.update.name}
                            onChange={e => updateData('name', e)} />
                    </div>
                    <div className={styles.uuid}>
                        <h4 className={styles.h4}>UUID</h4>

                        <input className={styles.inputUid} type="text" disabled value={props.badge._id} />

                    </div>
                    <div className={styles.DescriptionText}>
                        <h4 className={styles.h4}>Description</h4>
                        <textarea className={styles.inputs} value={data.update.secondaryInfo.description} onChange={e => updateSecondaryData('description', e)} />
                    </div>
                    <div className={styles.InstructionText}>
                        <h4 className={styles.h4}>Instructions</h4>
                        <textarea value={data.update.secondaryInfo.instruction} onChange={e => updateSecondaryData('instruction', e)} />
                    </div>
                    <div className={styles.CompletionText}>
                        <h4 className={styles.h4}>Completion</h4>
                        <textarea value={data.update.secondaryInfo.completion} onChange={e => updateSecondaryData('completion', e)} />
                    </div>
                    <div className={styles.ImagePicker}>
                        <h4 className={styles.h4}>Image</h4>
                        {photoThumbURL ? <img className={styles.image} src={photoThumbURL} /> : <img className={styles.image} src={data.update.secondaryInfo.thumbnail} />}
                        <input className={styles.filePicker} type="file" accept="image/x-png,image/gif,image/jpeg" style={{ marginTop: "12px" }} onChange={onFileChange} />
                    </div>
                    {props.badge.groupInfo.hideAfter ?
                        <div className={styles.Selector}>
                            <h4 className={styles.h4}>Availability</h4>
                            <div className={styles.DateContainer}>
                                <h5 className={styles.h5}>Hiden after</h5>
                                <input className={styles.dateInput} value={convert(date)} readOnly /*onChange={e => updateGroupInfo('hideAfter', e)}*/ type="date" />
                            </div>
                        </div> : null
                    }
                    <div className={styles.SaveButton} onClick={() => submitEdit()}>Save Badge</div>
                    <div className={styles.DeleteButton} onClick={deleteBadge}>Delete Badge</div>
                </div>
            </PopUpShell>
        </div >
    );
};

EditBadgePopUp.propTypes = {
    badge: PropTypes.object.isRequired,
    cancelAction: PropTypes.func,
    saveAction: PropTypes.func,
    addAction: PropTypes.func,
    deleteAction: PropTypes.func
};

export default EditBadgePopUp;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';
import './UserDetailsPopUp.scss'
import ChangeUserGroupPopUp from '../ChangeUserGroupPopUp';
import api from '../../../api'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {request} from '../../../api/index';
const UserDetailsPopUp = props => {
    console.log(props.group)
    const [editingGroup, setEditingGroup] = useState(false);

    const [userData, setUserData] = useState(null)
    const [date, setDate] = useState(new Date());
    const [addSteps, setAddSteps] = useState()
    const getUserData = async () => {
        try {
            const result = await api.groups.viewUserGroups({ user: `${props.group._id}` })
            setUserData(result)
        } catch (e) {
            console.log(e)
        }
    }
    const kickUser = async (group) => {
        try {
            const kickUser = await api.groups.kickUser({ group: `${group}`, user: `${props.group._id}` })
            console.log(kickUser)
            getUserData();
        } catch (e) { console.log(e) }
    }
    const addManualSteps = async (date, addSteps) => {
        console.log(date, addSteps)
        try {
            console.log(date,'------',addSteps)
            const addingSteps = await request('/sync/admin',  { user: props.group._id, steps: { [date]: addSteps }, distance: {} });
            props.closeAction();
        } catch (e) { console.log(e) }
    }
    useEffect(() => {
        getUserData()
    }, []);

    const getText = (data) => {
        if (!Date.parse(data)) return 'Not filled';

        const date = new Date(data);
        const text = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
        return text;
    }
    const getHeight = (data) => {
        if (!data) return 'Not filled';

        const feet = Math.floor(data / 12);
        const inches = data - (feet * 12);
        return feet + ' ft. ' + inches + ' in.';
    }
    const getGoals = (data) => {
        if (!data || !data.length) return 'No goals'
        return data.map(goal => goal.name).join(', ');
    }
    console.log('date', date.toISOString().replace('Z', '-0500'))
    return (
        <div className="loading-pop-up">
            {userData ?
                <PopUpShell>
                    <div className="UserDetailsPopUp">
                        <i className="material-icons close-icon" onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                        <div>
                            <h1>{props.group.firstName + ' ' + props.group.lastName}</h1>
                            <h1>{props.group._id}</h1>
                        </div>
                        {/* GROUP INFORMATION */}
                        <div >
                            <h4>This Quarter</h4>
                            <p>{props.group.quarterPoints} points</p>

                            <p>{props.group.quarterSteps} setps</p>
                            <p>Manually update steps</p>
                            <input className='addSteps' value={addSteps} onChange={event => setAddSteps(event.target.value.replace(/\D/,''))}/>
                            
                            <DatePicker className='dateInput' selected={date} onChange={date => setDate(date)} />
                        
                            <p>
                                        <button className='removeUserButton' onClick={e => {
                                    if (window.confirm(`Are you wanting to manually update ${addSteps} step's on date ${date} for the current selected user ? This action CANNOT be undone.`)) {
                                        console.log('getting there bro')
                                        addManualSteps(date.toISOString().replace('Z', '-0500'), parseInt(addSteps));
                                        // add function
                                    } else {
                                        // Do nothing, the user cancelled
                                    }
                                }}>Add steps</button>
                                </p>
                        </div>
                        <h4>Height</h4>
                        <p>{`${getHeight(props.group.secondaryInfo.heightCentimeters)}`}</p>
                        <h4>Weight</h4>
                        <p>{props.group.secondaryInfo.weightKilograms} lbs</p>
                        <h4>Birthday</h4>
                        <p>{`${getText(props.group.secondaryInfo.birthday)}`}</p>
                        <h4>Goal</h4>
                        <p>{`${getGoals(props.group.goals)}`}</p>
                        <h4>Zip Code</h4>
                        <p>{props.group.secondaryInfo.area}</p>
                        <h4>Groups accepted</h4>
                        {userData.memberGroups && userData.memberGroups.map(data => {
                            return (
                                <div className="UserListItem">
                                    {data ?
                                        <li key={data._id}>{`${data.name}`}
                                            <button className='removeUserButton' onClick={e => {
                                                if (window.confirm("Are you sure you want to kick this user out of selected group?  This action CANNOT be undone.")) {
                                                    kickUser(data._id);
                                                    // kickUser(groupData._id);
                                                } else {
                                                    // Do nothing, the user cancelled
                                                }
                                            }}>Remove From group</button>
                                        </li> : <h4 style={{ color: 'white' }}>User has not submitted a group request or has been accepted by any group</h4>}
                                </div>
                            );
                        })}
                        <h4>Groups Requested</h4>
                        {userData.requestedGroups && userData.requestedGroups.map(data => {
                            console.log('groups requested' + data)
                            return (
                                data ?
                                    <li key={data._id}>{`${data.name}`}
                                        <button className='removeUserButton' onClick={e => {
                                            if (window.confirm("Are you sure you want to kick user from request queue?  This action CANNOT be undone.")) {
                                                console.log('getting there bro')
                                                kickUser(data._id);
                                            } else {
                                                // Do nothing, the user cancelled
                                            }
                                        }}>Remove From group</button>
                                    </li> : null
                            );
                        })}
                        {/* <div className="buttons">
                            <button disabled onClick={e => {
                                setEditingGroup(true);
                            }}>Change Group</button>
                            <button className="delete-button" disabled onClick={e => {
                                if (window.confirm("Are you sure you want to delete?  This action CANNOT be undone.")) {
                                    window.alert("User confirmed deletion")
                                } else {
                                    // Do nothing, the user cancelled
                                    window.alert("User cancelled")
                                }
                            }}>Delete User</button>
                        </div> */}
                    </div>
                </PopUpShell>
                : null}
            {editingGroup ?
                <ChangeUserGroupPopUp user={props.user} closeAction={() => {
                    setEditingGroup(false);
                }} />
                : null}
        </div>
    )
}

UserDetailsPopUp.propTypes = {
    user: PropTypes.object.isRequired,
    closeAction: PropTypes.func
};

export default UserDetailsPopUp;
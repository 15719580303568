import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CardContainer = props => {
    return (
        <div className={styles.CardContainer}>
            {props.children}
        </div>
    );
};

CardContainer.propTypes = {};

export default CardContainer;
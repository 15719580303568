import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const GroupItem = props => {
    return (
        <div className={styles.GroupItemContainer}>
            <div className={styles.GroupItem} onClick={props.onClick}>
                <div className={styles.GroupColumn}>
                    <p>{props.groupColumn1}</p>
                </div>
                <div className={styles.GroupColumn}>
                    <p>{props.groupColumn2}</p>
                </div>
                {props.groupColumn3 ?
                    <div className={styles.GroupColumn}>
                        <p>{props.groupColumn3}</p>
                    </div>
                    : null}
                {props.groupColumn4 ?
                    <div className={styles.GroupColumn}>
                        <p>{props.groupColumn4}</p>
                    </div>
                    : null}
                {(props.groupColumn3 >= 4) ?
                    <div className={styles.BigTeamIdentifier}>
                        <i className="material-icons">whatshot</i>
                    </div>
                    : null}

            </div>
        </div>
    );
};

GroupItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    groupColumn1: PropTypes.any.isRequired,
    groupColumn2: PropTypes.any.isRequired,
    groupColumn3: PropTypes.any,
    groupColumn4: PropTypes.any
};

export default GroupItem; 
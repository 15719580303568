
import React, { useState } from 'react';
import style from './style.module.css';
import { Link } from 'react-router-dom';
import conditional from '../../utils/conditional';
import phone from '../../utils/phone';
import api from '../../api/index';

const SignIn = props => {
    
    const [data, setData] = useState({ phone: '+1 ' });
    const [status, setStatus] = useState('waiting');

    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };

    const containerClass = conditional('container', style, { 
        pending: status === 'pending',
        finished: status === 'finished'
    });

    const submit = async () => {
        try {
            const result = await api.auth.sendVerificationCode(phone.format(data.phone));
            
            localStorage.setItem('phone', data.phone);
            props.history.push('/verify');
        } catch (e) {
            const error = new Error('Incorrect credentials');
            error.code = 'incorrect-credentials';
            props.error(error);
        }
    };

    return (
        <div className={style.SignIn}>
            <div className={containerClass}>
                <div className={style.title + ' h1'}>Sign In With Phone</div>
                <input className={style.textField + ' i1'} placeholder="Phone" inputMode="numeric" value={data.phone} onChange={e => updateData('phone', e)} />
                <div className={style.submit + ' b1'} onClick={submit}><span className={'h1'}>Sign In</span></div>
            </div>
        </div>
    );

};

export default SignIn;

import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const RewardCard = props => {
    return (
        <div className={styles.BadgeCard}>
            <div className={styles.Information}>
                <h1>{props.badge.name}</h1>
            </div>
            <div className={styles.Buttons}>
                <button onClick={props.onEditClicked}>Add Choices</button>
            </div>
        </div>
    );
};

RewardCard.propTypes = {
    badge: PropTypes.object.isRequired,
    onEditClicked: PropTypes.func
}

export default RewardCard;

import { request, storage, mock } from './';

const sendVerificationCode = async phone => {
    try {
        const r = await request('/auth/change-code', { phone }, false);
        console.log(r)
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const verifyCode = async (phone, code) => {
    try {
        const result = await request('/auth/sign-in', { phone, password: code }, false);
        storage.save(result.data);
        return result.data;
    } catch (e) {
        return null;
    }
};

const signUp = async (data) => {
    console.log(data)
    const result = await request('/auth/sign-up', data);
    console.log(JSON.stringify(result.data))
    storage.save(result.data);
    return result.data;
};

export default { sendVerificationCode, verifyCode, signUp };

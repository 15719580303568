import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const BadgeCard = props => {
    return (
        <div className={styles.BadgeCard}>
            <div className={styles.Information}>
                <h1>{props.badge.name}</h1>
                <p className={styles.badgeID}>{props.badge._id}</p>
                <h4>Description</h4>
                <p>{props.badge.secondaryInfo.description}</p>
                <h4>Availability</h4>
                {/*Date to hide */}
                <h4>Requirements</h4>
                <p>{props.badge.secondaryInfo.instruction}</p>
            </div>
            <div className={styles.BadgeImage}>
                <img src={props.badge.secondaryInfo.photo} />
            </div>
            <div className={styles.Buttons}>
                <button onClick={props.onEditClicked}>Edit Badge</button>
            </div>
        </div>
    );
};

BadgeCard.propTypes = {
    badge: PropTypes.object.isRequired,
    onEditClicked: PropTypes.func
}

export default BadgeCard;
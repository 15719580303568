
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import api from '../../api';
const Analytics = props => {
    console.log(props)
    const [analytics, setAnalytics] = useState([]);

    const fetchData = async () => {
        try {
            const result = await api.analytics.getAnalytics({ 'organization': `${props.fetchId}` });
            setAnalytics(result);
        } catch (e) {
            console.log(e)
        }
    };

    const objectToCsv = (data) => {
        const CsvRows = [];

        const headers = Object.keys(data[0]);
        CsvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            CsvRows.push(values.join(','))
        }

        return CsvRows.join('\n');
    }

    const download = (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'donwload.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const getContact = async () => {
        console.log('failing to get in')
        // pass contact list to this data function
        const data = analytics.analytics.map(row => ({
            //how he wants the csv
            group: row.name,
            steps: row.totalSteps,
            points: row.totalPoints,
            leaderboardPoints: row.points
        }))
        // pass contacts json data to objectToCsv
        console.log('this is our csv data', data)
        const csvData = objectToCsv(data);
        download(csvData)

    }
    const getAll = () => {
        console.log('failing to get in')

        // // pass contact list to this data function
        let allUsers = []
        analytics.analytics.forEach(groupResult => {
            groupResult.userResults.forEach(userResult => {
                const user = {
                    name: `${userResult.firstName} ${userResult.lastName}`,
                    group: groupResult.name,
                    steps: userResult.aggregate.steps,
                    points: userResult.aggregate.points
                }
                allUsers.push(user)
            })
        })

        // Sort Here
        allUsers.sort((a, b) => {
            return b.points - a.points;
        });

        // pass contacts json data to objectToCsv
        console.log('this is our csv data', allUsers)
        const csvData = objectToCsv(allUsers);
        // console.log(csvData)
        download(csvData)
    }
    useEffect(() => { fetchData() }, [])
    console.log(analytics)
    return (
        <div className={style.ButtonContainer}>
            <h1 style={{ color: 'white', margin: '4px 0', textAlign: 'center' }}>Download {`${props.group}`} Analytics</h1>
            {/*first csv will download all groups and show their overall score as a group*/}
            <button className={style.buttons} onClick={() => getContact()}>Get Contact</button>
            {/*second csv will download all users within each group, sorting them from highest points to lowest*/}
            <button className={style.buttons} onClick={() => getAll()}>Get All</button>
        </div>
    );

};

export default Analytics;
import React, { Component } from "react";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import styles from './style.module.scss';

const Sidebar = props => {
    return (
        <section className={styles.sidebar}>
            <div className={styles.head}>
                <img className={styles.image} src={props.headerImage} alt="AMP Logo" />
            </div>
            <nav className={`${styles.navbar} ${props.classPass}`}>
                <ul className="navbar-nav mr-auto">
                    {props.children}
                </ul>
            </nav>
            <div className={styles.foot}>
                <ul className={styles.footUl}>
                    <li className={styles.footLi}>
                        <div onClick={() => {
                                localStorage.setItem('authData', null);
                                props.history.push('/sign-in');
                            }} className={styles.footButton} >
                            <i className="material-icons">exit_to_app</i>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}

Sidebar.propTypes = {
    headerImage: PropTypes.node.isRequired,
    onSignOutClicked: PropTypes.func,
    classPass: PropTypes.string
}

export default Sidebar;
import { request } from './';

const getGroups = async () => {
    const result = await request('/rewards/groups/fetch', {});
    return result.data;
};

const addGroups = async (data) => {
    const result = await request('/rewards/groups/add', data);
    return result.data;
};

const archiveGroup = async (data) => {
    const result = await request('/rewards/groups/archive', data);
    return result.data;
};

const addChoice = async (data) => {
    const result = await request('/rewards/groups/choices/add', data);
    return result.data;
};

const archiveChoice = async (data) => {
    const result = await request('/rewards/groups/choices/archive', data);
    return result.data;
};

const addTrigger = async (data) => {
    const result = await request('/rewards/groups/triggers/add', data);
    return result.data;
};

const archiveTrigger = async (data) => {
    const result = await request('/rewards/groups/triggers/archive', data);
    return result.data;
};

export default { getGroups, addGroups, archiveGroup, addChoice, archiveChoice, addTrigger, archiveTrigger };
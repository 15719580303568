import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';
import { app } from '../../../base';
import styles from './styles.module.scss';
import uuidv4 from '../../../utils/uuidv4';
import api from '../../../api';
const AddRewardPopUp = (props) => {
    const [photo, setPhoto] = useState(null);
    const [photoThumbURL, setPhotoThumbURL] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [data, setData] = useState({
        name: '',
        secondaryInfo: {
            description: '',
            instruction: '',
            completion: '',
            thumbnail: '',
            photo: ''
        },
    });
    const handleClick = () => setIsToggled(!isToggled)
    const updateData = (field, e) => {
        const value = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
        setData(data => ({ ...data, [field]: value }));
    };

    // const updateToAndFrom = (field, e) => {
    //     const valuefirst = moment(e.target.value).format('YYYY-MM-DD');
    //     const value = valuefirst + "T00:00:00.000-0400";
    //     setData(data => ({ ...data, [field]: value }));
    // };
    // const updateHideAfter = (field, e) => {
    //     const valuefirst = moment(e.target.value).format('YYYY-MM-DD');
    //     const value = valuefirst + "T00:00:00.000-0400";
    //     let newData = { ...data }
    //     let group = { ...newData.groupInfo }
    //     group[field] = value
    //     newData['groupInfo'] = group
    //     setData(newData)
    // }

    const updateSecondaryData = (field, e) => {
        const value = e.target.value;
        let newData = { ...data }
        let secondary = { ...newData.secondaryInfo }
        secondary[field] = value
        newData['secondaryInfo'] = secondary
        setData(newData)
    }


    const onFileChange = (e) => {
        if (!e.target || !e.target.files || e.target.files.length < 1) {
            return;
        }
        const file = e.target.files[0]
        setPhoto(file)
        const tImage = resizeImageToThumbnail(file)
        console.log(tImage)
        // Cleanup File Input
        if (e.target && e.target.value) {
            e.target.value = "";
        }
    };

    const uploadPhoto = async file => {
        console.log('this should break it but will help us', file)
        if (!file) {
            console.log('schiese lets go')
        } else {
            const p = new Promise((resolve, reject) => {
                const storageRef = app.storage().ref()
                const fileID = uuidv4();
                // Upload Original File
                const fileref = storageRef.child(`images/originals/${fileID}-${file.name}`)
                const uploadTask = fileref.put(file);
                uploadTask.on('state_changed', snap => {
                    var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                    // setPhotoProgress(progress)
                    // console.log('Upload is ' + progress + '% done');
                }, function (error) {
                    props.error(error);
                    reject(error)
                }, function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    });
                })
            })
            let url = '';
            await p.then(u => {
                url = u;
            })
            return url
        }
    };

    const uploadPhotoThumbnail = async imgSrc => {
        if (!imgSrc) {
            console.log('schiese lets go')
        } else {
            const p = new Promise((resolve, reject) => {
                const storageRef = app.storage().ref()
                const fileID = uuidv4();
                // Upload Thumbnail
                const thumbref = storageRef.child(`images/thumbnails/${fileID}-${photo.name}`)
                const thumbTask = thumbref.putString(imgSrc, 'data_url')
                thumbTask.on('state_changed', snap => {
                    var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                    // setThumbProgress(progress)
                    // console.log('Upload is ' + progress + '% done');
                }, function (error) {
                    props.error(error);
                    reject(error)
                }, function () {
                    thumbTask.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url);
                    });
                })
            })
            let url = '';
            await p.then(u => {
                url = u;
            })
            return url
        }
    };

    const resizeImageToThumbnail = img => {
        const oImage = new Image();
        oImage.onload = _ => {
            const resized = shrinkImage(oImage);
            console.log(resized)
            setPhotoThumbURL(resized.src);
        }
        oImage.src = URL.createObjectURL(img)
    };

    const shrinkImage = (img) => {
        // Create Canvas
        const canvas = document.createElement('canvas');
        canvas.style.display = "none";
        let ctx = canvas.getContext('2d');
        const size = 200;
        canvas.width = size;
        canvas.height = size;
        document.body.appendChild(canvas);

        // Calculate Size
        const offsetHorizontal = img.width >= img.height;
        const offsetPercentage = Math.abs((img.width - img.height) / 2) / (offsetHorizontal ? img.width : img.height);
        const offset = 0 - (offsetPercentage * size);
        const imageRatio = offsetHorizontal ? (img.width / img.height) : (img.height / img.width);
        const x = offsetHorizontal ? offset : 0;
        const y = !offsetHorizontal ? offset : 0;
        const width = offsetHorizontal ? size * imageRatio : size;
        const height = !offsetHorizontal ? size * imageRatio : size;
        ctx.drawImage(img, x, y, width, height);
        // Convert back to Image
        const tImage = new Image();
        tImage.src = canvas.toDataURL("image/png");

        // Cleanup DOM Elements and Revoke URLs
        document.body.removeChild(canvas)
        URL.revokeObjectURL(img.src)

        // Return resized Image
        return tImage;
    };

    const postData = async _ => {
        let postURL
        let thumbURL
        try {
            // postURL = await uploadPhoto(photo);
            // thumbURL = await uploadPhotoThumbnail(photoThumbURL);
            // let formatBadgeData = { ...data, secondaryInfo: { ...data.secondaryInfo, photo: postURL, thumbnail: thumbURL } }
            // setData(formatBadgeData)
            // console.log('updates image also sends old or new data: ', formatBadgeData)
            // // console.log('check second one ', data)

            await api.rewards.addGroups(data)
            props.closeAction();
        } catch (e) {
            console.log(e)
        }
    };


    // const currentRequirement = props.badge.requirements.steps !== null ? "steps" : "points";
    const submitNew = async () => {
        await postData();
    }

    console.log(data)

    return (
        <div className="loading-pop-up">
            <PopUpShell>
                <div className={styles.AddBadgePopUp}>
                    <i className={`${'material-icons'} ${styles.closeIcon}`} onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                    <h1 className={styles.h1}>{"Add Reward Group"}</h1>
                    <div className={styles.Title}>
                        <h4 className={styles.h4}>Title</h4>
                        <input
                            className={styles.input}
                            type="text"
                            //title ? title :
                            value={data.name}
                            onChange={e => updateData('name', e)} />
                    </div>
                    {/* <div className={styles.DescriptionText}>
                        <h4 className={styles.h4}>Description</h4>
                        <textarea className={styles.inputs} value={data.secondaryInfo.description} onChange={e => updateSecondaryData('description', e)} />
                    </div>
                    <div className={styles.InstructionText}>
                        <h4 className={styles.h4}>Instructions</h4>
                        <textarea className={styles.inputs} value={data.secondaryInfo.instruction} onChange={e => updateSecondaryData('instruction', e)} />
                    </div>
                    <div className={styles.CompletionText}>
                        <h4 className={styles.h4}>Completion</h4>
                        <textarea className={styles.inputs} value={data.secondaryInfo.completion} onChange={e => updateSecondaryData('completion', e)} />
                    </div>
                    <div className={styles.ImagePicker}>
                        <h4 className={styles.h4}>Image</h4>
                        {photoThumbURL ? <img src={photoThumbURL} /> : null}
                        <input className={styles.filePicker} type="file" accept="image/x-png,image/gif,image/jpeg" style={{ marginTop: "12px" }} onChange={onFileChange} />
                    </div> */}
                </div>
                <div className={styles.SaveButton} onClick={() => submitNew()}>Add Group</div>
            </PopUpShell>
        </div >
    );
};

AddRewardPopUp.propTypes = {
    closeAction: PropTypes.func,
};

export default AddRewardPopUp;
import React from "react";
import styles from './styles.module.scss';


const Toggle = ({istoggled,onToggle}) => {
    return (
        <label className={styles.switch}>
            <input type='checkbox'checked={istoggled} onClick={onToggle} />
            <span className={styles.slider} />
        </label>
    );
};

export default Toggle;
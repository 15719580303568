import React from 'react'
import PropTypes from 'prop-types'

import './Card.scss';

const Card = (props) => {
    return (
        <div className={`${"card"} ${props.classPass}`}>
            <h1>{props.title ? props.title : ""}</h1>
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}

Card.propTypes = {
    title: PropTypes.string,
    columnSpan: PropTypes.number
}

export default Card;
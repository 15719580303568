import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopUpShell from '../PopUpShell';

import './ChangeUserGroupPopUp.scss'


const ChangeUserGroupPopUp = (props) => {
    const [updatedGroupID, setUpdatedGroupID] = useState(null);

    const handleGroupIDChanged = (e) => {
        setUpdatedGroupID(e.target.value);
    }


    return (
        <div className="loading-pop-up">
            <PopUpShell>
                <div className="ChangeUserGroupPopUp">
                    <i className="material-icons close-icon" onClick={props.closeAction} style={{ cursor: 'pointer' }}>close</i>
                    <h1>Change Group</h1>
                    <h4>Group ID</h4>
                    <input type="text" value={updatedGroupID !== null ? updatedGroupID : props.groupID} onChange={handleGroupIDChanged} />
                    <div className="buttons">
                        <button onClick={e => {
                            if (window.confirm("Are you sure you want to change this users group?")) {
                                // Change the group the user is associated with
                               
                            } else {
                                // Do nothing, the user cancelled
                                window.alert("User cancelled");
                            }
                        }}>Change Group</button>
                        <button className="delete-button" onClick={e => {
                            props.closeAction();
                        }}>Cancel</button>
                    </div>
                </div>
            </PopUpShell>
        </div>
    )
}

ChangeUserGroupPopUp.propTypes = {
    user: PropTypes.object.isRequired,
    closeAction: PropTypes.func
};

export default ChangeUserGroupPopUp;
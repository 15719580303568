
import React, { useRef, useEffect, useState } from 'react';
import style from './style.module.css';
import timing from '../../utils/timing'
import api from '../../api';


const GroupJoinModal = props => {
    const [groupsShown, setGroupsShown] = useState([])


    const handleChange = async (value) => {
        try {
            console.log(value)
            const results = await api.groups.searchGroups({ 'search': `${value}` });
            setGroupsShown(results)
        } catch (e) {
            console.log(e)

        }
    }
console.log(groupsShown)
    return (
        <div>
            <div className={style.content}>
                <input type='text' onChange={timing.debounce(handleChange)} placeholder="Group Name..." className={style.groupInput} />
            </div>
        </div>
    );

};

export default GroupJoinModal;

import { request } from './';

// TODO: yes there will be another kind of goal thats not personal

const getAnalytics = async (data) => {
    const result = await request('/organizations/pull', data);
    return result.data;
};
const analytics = async (data) => {
    const result = await request('/organizations/fetch', {});
    return result.data;
};

// time: month, quarter, year
// type: averageSteps, changeAverageSteps

const archive = async (type, time) => {
    const result = await request('/analytics/archives/csv', { type, time });
    return result.data.data;
};

const averages = async (type, time) => {
    const result = await request('/analytics/averages', { type, time });
    console.log(type, time);
    // const contact = await request('/auth/contact/fetch', {});
    // console.log('prompting', contact.data);
    // const updated = await request('/auth/contact/update', { email: 'test@yo.com' });
    // console.log('updated', updated.data);

    return result.data;
};

const emails = async (type, time) => {
    const result = await request('/analytics/contacts', { query: {} });

    // const newUser = await request('/auth/contact/update', { email: 'gabe@geeky.media' });
    // const newUser2 = await request('/auth/contact/update', { email: 'gabethecoder@icloud.com' });
    

    return result.data;
};

const users = async query => {
    const result = await request('/analytics/users', { query });
    return result.data;
};

const groups = async query => {
    const result = await request('/analytics/groups', { query });
    return result.data;
};

const redemptions = async query => {
    const result = await request('/analytics/redemptions', { query });
    return result.data;
};

const registrations = async query => {
    const result = await request('/analytics/registrations', { query });
    return result.data;
};

export default { getAnalytics, analytics, averages, archive, emails, users, groups, redemptions, registrations };

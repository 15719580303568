import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const GroupItemContainer = props => {
    return (
        <div className={styles.GroupItemContainer}>
            <div className={styles.ContainerHeader}>
                <div className={styles.TitleColumn}>
                    <p>{props.column1}</p>
                </div>
                <div className={styles.TitleColumn}>
                    <p>{props.column2}</p>
                </div>
                {props.column3 ?
                    <div className={styles.TitleColumn}>
                        <p>{props.column3}</p>
                    </div>
                : null}
                {props.column4 ?
                    <div className={styles.TitleColumn}>
                        <p>{props.column4}</p>
                    </div>
                : null}
            </div>
            <div className={styles.ItemWrapper}>
                {props.children}
            </div>
        </div>
    );
};

GroupItemContainer.propTypes = {
    column1: PropTypes.string.isRequired,
    column2: PropTypes.string.isRequired,
    column3: PropTypes.string,
    column4: PropTypes.string
};

export default GroupItemContainer;